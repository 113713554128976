
export function recoverPasswordSendEmail(email: string){
    return `mutation{
        recoverPasswordSendEmail(recoverPasswordSendEmail:{
        email:"${email}"
        }){
            success
            message
            description
        }
        }`
}

export function recoverPasswordValidateCode(code: number){
    return `mutation{
        recoverPasswordValidateCode(recoverPasswordValidateCode:{
            code:"${code}"
        }){
            success
            message
            description
            token
        }
        }`
}

export function recoverPasswordSetNew(password: string){
    return `mutation{
        recoverPasswordSetNew(recoverPasswordSetNew:{
            newPassword:"${password}"
        }){
            success
            message
            description
        }
        }`
}